<template>
  <!--  TODO: es necesario tanto div?-->
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div id="main" class="col px-md-3 px-0 px-sm-3 mt-0">
          <div class="container main-container">
            <div class="row justify-content-center">
              <div class="col-12 px-4 px-md-0" style="min-height: 230px;">

                <div class="">
                  <img class="d-block mx-auto my-4" src="https://www.virginmobile.cl/sites/all/themes/virgin2017/assets/images/logo.svg" alt="Virgin Mobile" width="72" height="72">
                </div>

                <div class="mb-5">
                   <router-view/>
                </div>

                <div class="text-center pt-5">
                  <p><a href="https://virginmobile.cl" class="text-dark"><u>Volver a VirginMobile.cl</u></a></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Paga tu Plan - Virgin Mobile';
      }
    },
  }
};
</script>

<style scoped>
.main-container {
  max-width: 344px; /* adobe XD view port*/
}
</style>

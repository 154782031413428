import {AbstractApiService} from './AbstractApiService';

class PaymentService extends AbstractApiService {
    constructor() {
        super('', '');
    }

    show(msisdn) {
        // console.log('PaymetService.show ', msisdn);
        let response = this.http.post('/api/payment', {msisdn: msisdn})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
        // console.log('PaymentService.show response: ', JSON.stringify(response));
        return response;
    }

    create(msisdn, email, provider, distributor) {
        // console.log('PaymentService.create ', msisdn, email, provider, distributor);
        let response = this.http.post('/api/payment/create', {msisdn: msisdn, email: email, provider: provider, distributor: distributor})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
        // console.log('PaymentService.create response: ', JSON.stringify(response));
        return response;
    }

    get(buyorder) {
        // console.log('PaymetService.get ', buyorder);
        let response = this.http.get('/api/payment/' + buyorder)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
        // console.log('PaymentService.get response: ', JSON.stringify(response));
        return response;
    }
}

export const paymentService = new PaymentService();

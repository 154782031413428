<template>

  <div class="text-center">
    <p class="mb-5">
      Número consultado: <span class="text-primary font-weight-bold">{{ formattedMsisdn }}</span>
    </p>

    <div>
      <div v-if="loading">
        <div class="vld-icon"><svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="65" height="60" stroke="#ee0001"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform></path></g></g></svg></div>
      </div>
      <payment-not-virgin v-else-if="data.status === 'NotVirgin'" />
      <payment-tariff-active v-else-if="data.status === 'TariffActive'" />
      <payment-tariff-unknown v-else-if="data.status === 'TariffUnknown'" />
      <payment-tariff-form v-else-if="data.status === 'TariffReady'" :data="data" :msisdn="msisdn"/>
      <div v-else>
        <p>¡Ups! Algo salió mal</p>
        <small>{{data.message}}</small>
      </div>

    </div>

  </div>

</template>


<script>

import {paymentService} from '@/services/PaymentService'

export default {
  name: 'Payment',
  props: {
    msisdn: String
  },
  components: {
    // @ is an alias to /src
    'payment-not-virgin': () => import('@/components/PaymentNotVirgin'),
    'payment-tariff-active': () => import('@/components/PaymentTariffActive'),
    'payment-tariff-unknown': () => import('@/components/PaymentTariffUnknown'),
    'payment-tariff-form': () => import('@/components/PaymentTariffForm')
  },
  data() {
    return {
      status: '',
      data: {},
      loading: true,
    }
  },

  mounted() {
    if (this.msisdn.length !== 11 || !this.msisdn.startsWith('569') || !/\d{11}$/.test(this.msisdn)) {
      // console.log('invalid msisdn redirect home')
      this.$router.push({name: 'home'})
    }
    this.show();
  },

  computed: {
    formattedMsisdn() {
      return this.msisdn.toString().substring(2, 3) + ' ' + this.msisdn.toString().substring(3, 7) + ' ' + this.msisdn.toString().substring(7, 11)
    }
  },

  methods: {
    async show() {
      // console.log('show: ', this.msisdn);
      let data = await paymentService.show(this.msisdn);
      if (!data) {
        return false;
      }
      this.data = data;
      this.loading = false;
    },

  }
}
</script>
<style scoped>
</style>



import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Payment from '../views/Payment.vue'
import PaymentSuccess from "@/views/PaymentSuccess";
import PaymentFailure from "@/views/PaymentFailure";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,    
    // meta: {
    //   title: 'Paga tu Plan - Virgin Mobile'
    // }
  },
  {
    path: '/payment/:msisdn',
    name: 'payment',
    component: Payment,
    props: true,
  },
  {
    path: '/payment/:buyOrder/success',
    name: 'payment_success',
    component: PaymentSuccess,
    props: true,
  },
  {
    path: '/payment/:buyOrder/failure',
    name: 'payment_failure',
    component: PaymentFailure,
    props: true,
  },


  {
    path: '/:catchAll(.*)', 
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    props: true,
    name: 'not-found'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

<template>
  <div>

    <div class="text-center" v-if="loading">
      <div class="vld-icon"><svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="65" height="60" stroke="#ee0001"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform></path></g></g></svg></div>
    </div>

    <div v-if="!loading && payment && payment.status === 'OK'">

      <div class="text-center mb-3">
        <img class="mb-3" src="/images/check.svg" alt="pago ok">
        <p class="mb-0"><strong>Plan pagado</strong></p>
        <p v-if="msisdn">Para el número: <strong>{{ formattedMsisdn}}</strong></p>
      </div>

      <div class="mb-5">
        <taiff-card :name="payment.tariff.name" :include="payment.tariff.include" :price="payment.tariff.price"/>

        <div v-if="payment.loan > 0">
          <div  class="card border-1 mb-2">
            <div class="card-body pt-2 pb-2 px-3">
              <div class="row">
                <div class="col-9 small">
                  <p class="mb-0">Deuda préstamo:</p>
                </div>
                <div class="col-3 small text-right">
                  <p class="text-primary mb-0"><strong>${{ payment.loan.toLocaleString('es-CL') }}</strong></p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <p>Total pagado:
              <span class="text-primary">
            <strong>${{ payment.amount.toLocaleString('es-CL') }}</strong>
          </span>
            </p>
          </div>
        </div>

      </div>

      <div class="text-center">
        <h4 class="lh-4 font-weight-normal">El plan se activará automáticamente en unos segundos</h4>
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src


import {paymentService} from "@/services/PaymentService";

export default {
  name: 'PaymentSuccess',
  props: {
    'buyOrder': String
  },
  computed: {
    formattedMsisdn() {
      return this.msisdn.toString().substring(2, 3) + ' ' + this.msisdn.toString().substring(3, 7) + ' ' + this.msisdn.toString().substring(7, 11)
    }
  },
  data() {
    return {
      loading: true,
      msisdn: "",
      payment: ""
    }
  },
  setup() {
    return {
      // loading: true,
      // payment: true
    }
  },
  components: {
    'taiff-card': () => import('@/components/TariffCard.vue'),
  },
  mounted() {
      this.get(this.buyOrder)
  },

  methods: {
    async get() {
      this.payment = await paymentService.get(this.buyOrder);
      this.msisdn = this.payment.msisdn;
      this.loading = false;
    },

  }
}
</script>

<template>
  <msisdn-form :msisdn="msisdn" />
</template>

<script>
// @ is an alias to /src
import MsisdnForm from '@/components/MsisdnForm.vue'

  export default {
    name: 'Home',
    props: {
      'msisdn': String
    },
    components: {
      MsisdnForm,
    },
  }
</script>

<!--TODO: limitar el número de caracteres en el input de msisdn-->

<template>
  <div class="text-center">
    <h4 class="justify-center mb-4"><strong>Paga tu plan <br> Virgin Mobile</strong></h4>

    <form ref="form" @submit.prevent="submit">
      <div class="form-group">
        <label for="msisdn" class="mb-3">Ingresa tu número</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">+56</span>
          </div>
          <input id="msisdn" type="number" class="form-control" v-bind:class="{ 'is-invalid': errors.msisdn }"
                 name="msisdn" autofocus
                 autocomplete="msisdn" pattern="[0-9]{9}" maxlength="9" max="9999999999" placeholder="9 9876 5432"
                 v-model.number="msisdn">
        </div>
        <div v-if="errors.msisdn" class="invalid-feedback">
          Ingresa un número válido
        </div>

      </div>

      <button class="btn btn-block btn-primary mb-3" type="submit">
        Continuar
      </button>

    </form>

    <p class="bg-light font-size-sm m-0 p-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round" class="feather feather-lock" data-v-523d50ee="">
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
      </svg>
      El pago de tu plan está seguro y protegido
    </p>

  </div>

</template>

<script>

export default {
  name: 'MsisdnForm',
  props: {},

  data() {
    return {
      errors: {
        msisdn: false
      },
      msisdn: '',
    }
  },

  methods: {
    submit() {
      this.errors.msisdn = false;
      const msisdnIsValid = this.msisdn.toString().length === 9 && this.msisdn.toString().startsWith('9') && typeof this.msisdn === 'number'
      if (!msisdnIsValid) {
        this.errors.msisdn = true;
        return false;
      }

      this.$router.push({
            name: 'payment',
            params: {
              "msisdn": "56" + this.msisdn
            }
          }
      )

    }
  }
}
</script>

<style scoped>
</style>


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'

import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// import { createPinia, PiniaVuePlugin } from 'pinia'
import "./assets/css/vendor.css"
import "./assets/css/main.css" 


// Vue.use(PiniaVuePlugin)
// const pinia = createPinia()

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  // vuetify,
  // pinia
}).$mount('#app')

// window.feather.replace({width: '1em', height: '1em'});
